import React, { useEffect, useRef, useState } from "react";
import {
  ButtonOption,
  Card,
  Container,
  DescriptionStep,
  DivCenter,
  DivOptions,
  DivRow,
  Option,
  SelectCustom,
  Title,
} from "./styes";
import Button from "../../components/Button/Button";
import { FaArrowLeft } from "react-icons/fa";
import { FiCheckCircle, FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import api from "../../services";
import { uploadFile } from "../../services/cliente/upload";
import { formatPhoneNumber, regioes as regioesNomes } from "../../utils/helpers";
import { useHistory } from "react-router-dom";

const Register = () => {
  const inputRef = useRef(null);
  const history = useHistory();
  const [active, setActive] = useState(1);
  const [step, setStep] = useState(1);
  const [payload, setPayload] = useState();
  const [segmentos, setSegmentos] = useState();
  const [segmentoSelected, setSegmentoSelected] = useState("");
  const [publicoAlvo, setPublicoAlvo] = useState();
  const [publicoAlvoSelected, setPublicoAlvoSelected] = useState([]);
  const [isPictureLoading, setIsPictureLoading] = useState(false);
  const [logoSelected, setLogoSelected] = useState("");
  const [cidades, setCidades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidadeSelected, setCidadeSelected] = useState("");
  const [estadoSelected, setEstadoSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }, [])

  // useEffect(() => {
  //   getSegmentos();
  //   getPublicoAlvo();
  // }, []);

  useEffect(() => {
    const estadosOrdenados = regioesNomes.regioes
      .flatMap((regiao) => regiao.estados.map((estado) => estado))
      .sort();

    setEstados(estadosOrdenados);
  }, []);

  const getSegmentos = async () => {
    try {
      const result = await api.get("api/v1/segmentos/");
      if (result) {
        const { data } = result;
        if (data) {
          setSegmentos(data);
        }
      }
    } catch (error) {
      toast.error("Ocorreu um problema ao buscar a lista de segmentos");
    }
  };

  const getPublicoAlvo = async () => {
    try {
      const result = await api.get("api/v1/publico_alvo/");
      if (result) {
        const { data } = result;
        if (data) {
          const _results = data.map((item) => {
            return {
              id: item.id,
              value: item.nome,
              label: item.nome,
            };
          });
          setPublicoAlvo(_results);
        }
      }
    } catch (error) {
      toast.error("Ocorreu um problema ao buscar a lista de público alvo");
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      try {
        setIsLoading(true);

        const cliente = {
          // profile_picture: logoSelected ? logoSelected : "",
          nome: payload.nome.trim(),
          // sobrenome: payload.sobrenome.trim(),
          email: payload.email.trim(),
          nome_empresa: payload.nome_empresa,
          ver_endereco: true,
          celular: payload.celular,
          whatsapp: payload.celular,
          telegram: payload.celular,
          // instagram: payload.instagram,
          // tiktok: "",
          // segmento_influence: segmentoSelected,
          // publico_alvo: publicoAlvoSelected,
          // estado: estadoSelected,
          // cidade: cidadeSelected,
          // nota: 1,
          password: payload.password.trim(),
        };

        const result = await api.post("/api/v1/clientes/", cliente);
        if (result) {
          toast.success("Cadastro realizado, cheque seu e-mail!");
          setIsLoading(false);
          if (window.fbq) {
            window.fbq('track', 'purchase', {
                content_name: "/signup",
                event_description: 'Usuário clicou em um link',
            });
        }
          return history.push("/confirm-register");
        }
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.data && error.response.data.details) {
          toast.error(`${error.response.data.details}`);
        } else {
          toast.error(`${error}`);
        }
      }
    }
  };

  const validate = () => {
    if (!payload?.nome) {
      toast.error("O campo nome precisa ser preenchido");
      return false;
    }
    // if (!payload?.email) {
    //   toast.error("O campo email precisa ser preenchido");
    //   return false;
    // }
    // if (!payload?.sobrenome) {
    //   toast.error("O campo sobrenome precisa ser preenchido");
    //   return false;
    // }
    // if (!payload?.celular) {
    //   toast.error("O campo telefone precisa ser preenchido");
    //   return false;
    // }
    if (!payload?.nome_empresa) {
      toast.error("O campo nome da empresa precisa ser preenchido");
      return false;
    }
    // if (!estadoSelected) {
    //   toast.error("O campo estado precisa ser preenchido");
    //   return false;
    // }
    // if (!cidadeSelected) {
    //   toast.error("O campo cidade precisa ser preenchido");
    //   return false;
    // }
    // if (publicoAlvoSelected.length === 0) {
    //   toast.error("É preciso selecionar pelo menos um público alvo");
    //   return false;
    // }
    // if (segmentoSelected.length === 0) {
    //   toast.error("É preciso selecionar o segmento");
    //   return false;
    // }
    if (!payload?.password) {
      toast.error("O campo senha é obrigatório");
      return false;
    }
    if (!payload?.passwordConfirm) {
      toast.error("O campo confirmação de senha é obrigatório");
      return;
    } else if (payload?.password !== payload?.passwordConfirm) {
      toast.error("A confirmação de senha é diferente da senha");
      return false;
    }
    return true;
  };

  const onPress = () => {
    if (active === 1){
      setStep(step +1);
    } else if (active === 2) {
      history.push("/links-creator");
    }
  };

  const handleChange = (key, value) => {
    if (key === "estado") {
      setCidades(estados.find((r) => r.nome === value).cidades);
      setEstadoSelected(value);
    } else if (key === "cidade") {
      setCidadeSelected(value);
    } else {
      setPayload({ ...payload, [key]: value });
    }
  };

  const toggleSegmentoSelection = (id) => {
    setSegmentoSelected(id);
  };

  const togglePublicoAlvoSelection = (name) => {
    setPublicoAlvoSelected((prevSelected) => {
      if (prevSelected.includes(name)) {
        // Se o ID já está selecionado, remova-o
        return prevSelected.filter((selectedId) => selectedId !== name);
      } else {
        // Se não está selecionado, adicione-o
        return [...prevSelected, name];
      }
    });
  };

  const handleDivClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = async (e) => {
    if (e.target.files.length < 1) {
      return;
    }

    setIsPictureLoading(true);

    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    const url = await uploadFile("cliente", formData);

    setLogoSelected(url);

    setIsPictureLoading(false);
  };


  return (
    <Container>
      {step === 1 ? (
        <Card>
          <div>
            <Title>Você é Creator ou Marca?</Title>
            <DivOptions>
              <Option active={active === 1} onClick={() => setActive(1)}>
                Sou Marca
                {active === 1 ? <FiCheckCircle size={24} color="#fff" /> : null}
              </Option>
              <Option active={active === 2} onClick={() => setActive(2)}>
                Sou Creator
                {active === 2 ? <FiCheckCircle size={24} color="#fff" /> : null}
              </Option>
            </DivOptions>
          </div>
          <Button
            setBackgroundColor={"var(--yellow)"}
            setColor={"var(--black)"}
            style={{ width: "100%" }}
            onClick={() => onPress()}
          >
            Continuar
          </Button>
        </Card>
      ) : step === 2 ? (
        <Card>
          <FaArrowLeft onClick={() => setStep(step - 1)} />
          <div>
            {/* <DescriptionStep>Passo 01 de 03</DescriptionStep> */}
            <Title style={{ marginBottom: 10, textAlign: "center" }}>
              Preencha os Dados
            </Title>
            {/* <label htmlFor="">Selecione Sua Foto de Perfil</label>
            <DivCenter>
              {logoSelected ? (
                <img src={logoSelected} alt="Logo" />
              ) : (
              <div className="logo" onClick={handleDivClick}>
                <FiPlus size={34} />
                <span>
                  Clique Para <br /> Selecionar
                </span>
                <input
                  ref={inputRef}
                  name="Logo"
                  label="Logo"
                  type="file"
                  accept="image/*"
                  id="contained-button-file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              )}
            </DivCenter> */}
            <label htmlFor="nome_empresa">Nome da Empresa</label>
            <input
              type="text"
              id="nome_empresa"
              required
              value={payload?.nome_empresa}
              onChange={({ target }) =>
                handleChange("nome_empresa", target.value)
              }
            />
            <label htmlFor="nome">Seu Nome Completo</label>
            <input
              type="text"
              id="nome"
              required
              value={payload?.nome}
              onChange={({ target }) => handleChange("nome", target.value)}
            />
            {/* <label htmlFor="sobrenome">Seu Sobrenome</label>
            <input
              type="text"
              id="sobrenome"
              required
              value={payload?.sobrenome}
              onChange={({ target }) => handleChange("sobrenome", target.value)}
            /> */}
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              required
              value={payload?.email}
              onChange={({ target }) => handleChange("email", target.value)}
            />
            <label htmlFor="celular">Telefone</label>
            <input
              type="tel"
              id="celular"
              required
              value={payload?.celular ? formatPhoneNumber(payload.celular) : ""}
              onChange={({ target }) => handleChange("celular", target.value)}
            />
          </div>
          {/* <label style={{ marginBottom: 4 }}>Selecione seu Estado:</label>{" "}
          <SelectCustom
            required
            onChange={({ target }) => handleChange("estado", target.value)}
          >
            <option selected>Selecione Uma Opção</option>
            {estados.map((regiao, index) => (
              <option key={index} value={regiao.nome}>
                {regiao.nome}
              </option>
            ))}
          </SelectCustom>
          <label style={{ marginBottom: 4 }}>Selecione sua Cidade:</label>{" "}
          <br />
          <SelectCustom
            required
            onChange={({ target }) => handleChange("cidade", target.value)}
          >
            <option selected>Selecione Uma Opção</option>
            {cidades.map((regiao, index) => (
              <option key={index} value={regiao}>
                {regiao}
              </option>
            ))}
          </SelectCustom> */}
          {/* <Button
            setBackgroundColor={"var(--yellow)"}
            setColor={"var(--black)"}
            style={{ marginTop: 10, width: "100%" }}
            onClick={() => setStep(step + 1)}
          >
            Próximo
          </Button>
        </Card>
      ) : step === 3 ? (
        <Card>
          <FaArrowLeft onClick={() => setStep(step - 1)} /> */}
          <div>
            {/* <Title style={{ marginTop: 30, marginBottom: 10, textAlign: "center" }}>
              Redes Sociais e Seguimentos
            </Title>
            <label htmlFor="instagram">Instagram</label>
            <span>Digite apenas o nome de usuário sem o '@'</span>
            <input
              type="text"
              id="instagram"
              required
              value={payload?.instagram}
              onChange={({ target }) => handleChange("instagram", target.value)}
            />

            <label htmlFor="categorias">
              Selecione as categorias que melhor te representam
            </label>
            <DivRow>
              {publicoAlvo.length
                ? publicoAlvo.map((item) => (
                    <ButtonOption
                      key={item.id}
                      active={publicoAlvoSelected.includes(item.label)}
                      onClick={() => togglePublicoAlvoSelection(item.label)}
                    >
                      {item.value}
                    </ButtonOption>
                  ))
                : null}
            </DivRow>

            <label htmlFor="categorias">
              Selecione o segmento que melhor te representam
            </label>
            <DivRow>
              {segmentos.length
                ? segmentos.map((item) => (
                    <ButtonOption
                      key={item.id}
                      active={segmentoSelected === item.id}
                      onClick={() => toggleSegmentoSelection(item.id)}
                    >
                      {item.nome}
                    </ButtonOption>
                  ))
                : null}
            </DivRow> */}
            <label htmlFor="tiktok">Senha</label>
            <input
              type="password"
              id="password"
              required
              autoComplete=""
              value={payload?.password}
              onChange={({ target }) => handleChange("password", target.value)}
            />
            <label htmlFor="tiktok">Confirme Sua Senha</label>
            <input
              type="password"
              id="passwordConfirm"
              required
              value={payload?.passwordConfirm}
              onChange={({ target }) =>
                handleChange("passwordConfirm", target.value)
              }
            />
          </div>
          {/* <Button
            setBackgroundColor={"var(--yellow)"}
            setColor={"var(--black)"}
            style={{ marginTop: 20, width: "100%" }}
            onClick={() => setStep(step + 1)}
          >
            Próximo
          </Button>
        </Card>
      ) : (
        <Card>
          <FaArrowLeft onClick={() => setStep(step - 1)} />
          <div>
            <DescriptionStep>Passo 03 de 03</DescriptionStep>
            <Title style={{ marginBottom: 10, textAlign: "center" }}>
              Selecione Sua Foto de Perfil
            </Title>
            <DivCenter>
              {logoSelected ? (
                <img src={logoSelected} alt="Logo" />
              ) : (
              <div className="logo" onClick={handleDivClick}>
                <FiPlus size={58} />
                <span>
                  Clique Para <br /> Selecionar
                </span>
                <input
                  ref={inputRef}
                  name="Logo"
                  label="Logo"
                  type="file"
                  accept="image/*"
                  id="contained-button-file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              )}
            </DivCenter>
          </div> */}
          <Button
            setBackgroundColor={"var(--yellow)"}
            setColor={"var(--black)"}
            style={{ marginTop: 20, width: "100%" }}
            disabled={isLoading}
            onClick={() => handleSubmit()}
          >
            Finalizar
          </Button>
        </Card>
      ) : <></>}
    </Container>
  );
};

export default Register;
