import React, { useEffect, useRef, useState } from "react";
import {
  Content,
  DivColumn,
  DivRow,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "./styles";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { DivBetween, Title } from "../VitrineCreators/styles";
import api from "../../services";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import { formatDateHour } from "../../utils/helpers";
import { FaArrowLeft, FaArrowRight, FaCheck, FaRegUserCircle } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { TbArrowBackUp } from "react-icons/tb";
import { toast } from "react-toastify";
import { DivCenter } from "../ListCreators/styles";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner";
import { alterStatusCampanha } from "../../providers/solicitation";

const DetailCampaign = () => {
  const { auth } = useAuth();
  const { clientId } = useClient();
  const location = useLocation();
  const history = useHistory();
  const page = useRef(1);
  const totalPages = useRef();
  const [campanha, setCampanha] = useState();
  const [candidaturas, setCandidaturas] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    if (location) {
      const { state } = location;
      const id = state.id;

      setCampanha(state);
      getCandidaturas(1, id);
    } else {
      history.goBack();
    }
  }, [location]);

  const getCandidaturas = async (_page, id) => {
    try {
      setLoading(true);
      let params = {
        page: _page ? _page : page.current,
        id_cliente: clientId,
        id_campanha: id ? id : campanha.id,
        status: "0,1,2", // Pendente, Aprovado ou Rejeitado
        search: search ? search : searchValue,
      };

      const result = await api
        .get(`api/v1/candidaturas/`, {
          params,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          setLoading(false);
          totalPages.current = res.data.num_pages;
          page.current = params.page;
          setCandidaturas(res.data.results);
        });
      } catch (error) {
      setLoading(false);
      console.error("Erro: ", error);
    }
  };

  const handlePortfolio = async (link, idInfluencer) => {
    try {
      let url = `${link}`;

      let params = {
        influencer: idInfluencer,
        tipo_notificacao: 10,
      };

      window.open(url, "_blank", "noopener,noreferrer");

      if (clientId) {
        params["cliente"] = clientId;
        await api.post("/api/v1/eventos/instagram/", params, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });
      } else {
        await api.post("/api/v1/eventos/instagram/", params);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const handlePagination = (_page) => {
    if (_page < 1 || _page > totalPages.current) return;

    getCandidaturas(_page, null);
  }

  const handleAlterStatusCampanha = async (status) => {
    setLoadingStatus(true);
    try {
      let _status;
      if (status === "aceito") {
        _status = 1
      } else if (status === "recusado") {
        _status = 2
      }

      const result = await alterStatusCampanha(
        campanha.id,
        _status,
        auth
      );
      if (result) {
        const _candidaturas = candidaturas.map(item => {
          if (item.id === result.id) {
            return { ...item, status: result.status }
          }
          return item
        })
        setCandidaturas(_candidaturas);

        toast.success('Salvo com sucesso!');
        setLoadingStatus(false);
      }
    } catch (error) {
      toast.error(`${error.message}`);
      setLoadingStatus(false);
    }
  }

  return (
    <Content>
      <DivRow className="align-center" onClick={() => history.goBack()} style={{ cursor: "pointer", width: "fit-content" }}>
        <TbArrowBackUp size={34} title="Voltar" />
        <p style={{ width: "fit-content" }}>Voltar</p>
      </DivRow>
      <DivBetween style={{ marginTop: 20, marginBottom: 14 }}>
        <Title>{campanha?.nome_campanha}</Title>
      </DivBetween>
      <DivRow style={{ overflowX: "auto" }}>
        <Table hasData={candidaturas.length > 0}>
          <TableRow>
            <TableHead style={{ textAlign: "left" }}>Inscritos</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Ações</TableHead>
          </TableRow>
          <TableBody>
            {candidaturas.map((item) => (
              <TableRow style={{ cursor: "default" }}>
                <td className="small">
                  <DivRow className="align-center">
                    <img
                      className="profile_picture"
                      src={item.influencidor.profile_picture}
                    />
                    <DivColumn style={{ width: "80%" }}>
                      <p className="nome">
                        {item.influencidor.nome}{" "}
                        <span className="data">
                          {item.data_candidatura
                            ? formatDateHour(item.data_candidatura)
                            : null}
                        </span>
                      </p>
                      <p>{item.influencidor.sobre}</p>
                    </DivColumn>
                  </DivRow>
                </td>
                <td align="center">
                  <button className={item.status}>{item.status}</button>
                </td>
                <td align="center">
                  <DivRow
                    style={{ flexWrap: "nowrap", marginBottom: 10, gap: 8 }}
                  >
                    <div
                      className="portifolio"
                      title="Ver Portfólio"
                      onClick={() =>
                        handlePortfolio(
                          item.influencidor.link_portifolio,
                          item.influencidor.id
                        )
                      }
                    >
                      <FaRegUserCircle size={16} color="var(--lightgray)" />
                      <span>Portfólio</span>
                    </div>
                    <div
                      className={
                        item.status !== "Pendente"
                          ? "action disabled"
                          : "action"
                      }
                      title="Recusar"
                      onClick={() => handleAlterStatusCampanha("recusado")}
                    >
                      <IoMdClose color="var(--red)" />
                    </div>
                    <div
                      className={
                        item.status !== "Pendente"
                          ? "action disabled"
                          : "action"
                      }
                      title="Aceitar"
                      onClick={() => handleAlterStatusCampanha("aceito")}
                    >
                      <FaCheck color="var(--green)" />
                    </div>
                  </DivRow>
                </td>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DivRow>
      {candidaturas.length === 0 && !loading ? (
        <DivRow className="justify-center">
          <span style={{ fontSize: 24, fontWeight: 600 }}>Sem Candidaturas</span>
        </DivRow>
      ) : null}
      <DivCenter style={{marginBottom: 10}}>
          <Button
            setBackgroundColor={'var(--white)'}
            setColor={'var(--back)'}
            disabled={loading || page.current === 1}
            onClick={() => handlePagination(page.current - 1)}
            >
            <FaArrowLeft size={10}  />{" "}
            Anterior
          </Button>
          <Button
            setBackgroundColor={'var(--white)'}
            setColor={'var(--back)'}
            disabled={loading || page.current === totalPages.current}
            onClick={() => handlePagination(page.current + 1)}
          >
            Próximo{" "}
            <FaArrowRight size={10}  />
          </Button>
        </DivCenter>
        {loading ? <Spinner /> : <div style={{ height: 80 }}></div>}
    </Content>
  );
};

export default DetailCampaign;
