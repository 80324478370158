import styled from "styled-components";

export const Content = styled.div`
  background-color: var(--white-50);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  min-height: 90dvh;
  width: 100%;
  padding: 50px 100px;

  /* @media (max-width: 850px) {
    width: calc(100% - 155px);
  } */

  @media (max-width: 700px) {
    padding: 40px 20px;
  }

  @media (max-width: 465px) {
    padding: 40px 20px;
  }
  
  @media (max-width: 425px) {
    /* width: calc(100% - 135px); */
    padding: 40px 10px;
  }
`; 

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 8px 0 30px;
  width: 100%;

  & > img {
    margin-right: 10px;
  }

  & > .profile_picture {
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }

  & > .portifolio, & > .action {
    background-color: var(--graycard);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 10px;
  }

  & > .portifolio {
    white-space: nowrap;

    & svg {
      margin-right: 4px;
    }
  }

  & > .disabled {
    opacity: 0.5;
    cursor: no-drop;
  }

  & > h3 {
    color: var(--brightpurple);
    margin-right: 8px;
  }

  &.justify-between {
    justify-content: space-between;
  }

  .justify-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  button {
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 30px;

    & > img {
      margin-right: 10px;
    }
  }

  .secondary {
    background: var(--lightpurple);
    color: var(--black);
  }

  .primary {
    background: var(--brightpurple);
    color: var(--white);
  }

  .danger {
    background: var(--red);
    color: var(--white);
  }

  @media (max-width: 550px) {
    flex-wrap: wrap;
     p {
      width: 100% !important;
     }
  }

  @media (max-width: 500px) {
    button {
      margin-inline: 0;
      margin-bottom: 6px !important;
      width: 100%;
    }
  }
`;

export const Card = styled.div`
  background-color: var(--white);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  flex: 1 1 48%;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  margin-inline: 8px;
  min-height: 200px;
  width: 48%;
  padding: 12px;

  & h2 {
    font-family: Inconsolata;
    font-weight: 400;
    margin-bottom: 10px;
  }

  & p {
    font-family: Inconsolata;
    font-weight: 400;
    margin-bottom: 20px;
  }

  & span {
    font-weight: 600;
  }

  & > p {
    margin-bottom: 8px;
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 20px;
    }
  }
`;

export const Table = styled.table`
  border-spacing: 0 10px;
  margin-top: 30px;
  width: 100%;
  ${(props) => props.hasData && 'min-width: 930px;'}
  overflow-x: auto;
`;

export const TableRow = styled.tr`
   padding: 0.5em; 
   border-radius: 10px;
   overflow: hidden;

   td {
    padding: 0.3em;
   }

   & > .small {
    width: 50%;
   } 
`;

export const TableHead = styled.th`
  background-color: transparent;
  /* border: 1px solid gray; */
  padding: 10px;
  width: 100%;
  white-space: nowrap;
`;

export const TableBody = styled.tbody`

  & > tr{
    background-color: var(--white);
    border: 1px solid gray;
    cursor: pointer;

    & .name {
      font-weight: 800;
    }
    
    & > td {
      border: none;
      padding: 1rem 10px;

      & button {
        border: 1px solid var(--black);
        cursor: default;
        font-weight: 600;
        white-space: nowrap;
      }

      & .EmAndamento, & .Aprovado {
        background-color: var(--lightgreen);
      }

      & .Finalizado, & .Rejeitado {
        background-color: var(--red);
        color: var(--white);
      }

      & .Pendente {
        background-color: var(--lightalert);
      }
    }
  }
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > h2 {
    color: var(--brightpurple);
    font-size: 26px;
    margin: 20px 20px 20px 4%;
  }

  & > p {
    font-size: 12px;
    width: fit-content;
  }

  & > .nome {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  & > .nome > .data {
    color: var(--mediumgray);
    font-size: 13px;
    font-weight: 400;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;
